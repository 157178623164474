import React from "react"
import $ from "jquery"
import emailjs from 'emailjs-com';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/map"

import { IconContext } from "react-icons"
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelopeOpenText, FaTimes } from "react-icons/fa"

import contactStyles from "../styles/pages/contact.module.css"
import reachOut from "../sounds/reach-out.mp3"

const ContactPage = () => {

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('contact_us_service', 'contact_us_email_template', e.target, 'user_hjQ9yOXDK7JqbvTpiWr56')
			.then(function(response) {
			  $("#email-sent").addClass(contactStyles.in);
        $("#error-sending").removeClass(contactStyles.in);
        $("#message-form")[0].reset();
        setTimeout(function() {$("#email-sent").removeClass(contactStyles.in);}, 5000);
			}, function(error) {
				$("#email-sent").removeClass(contactStyles.in);
        $("#error-sending").addClass(contactStyles.in);
        $("#message-form")[0].reset();
        setTimeout(function() {$("#error-sending").removeClass(contactStyles.in);}, 5000);
      });
  }

  function play(e) {
    e.preventDefault();
    var music = document.getElementById("music");
    music.play();
  }

  return (
    <Layout location="contact">
      <SEO title="Contact Us" />
      <div className={ `${contactStyles.alertSuccess} ${contactStyles.fade}` } id="email-sent" role="alert">
        <p><strong>An email has been sent to us!</strong> We will reply to it as soon as possible.</p>
      </div>
      <div className={ `${contactStyles.alertDanger} ${contactStyles.fade}` }  id="error-sending" role="alert">
        <p><strong>Something went wrong!</strong> Please try again.</p>
      </div>
      <div className={ contactStyles.header }>
        <div className={ contactStyles.headerImage }></div>
        <div className={ contactStyles.headerText }>
          <h4 className={ contactStyles.title }>Contact Us</h4>
          <p className={ contactStyles.subtitle }>We are Executive Decisions and we are your team.</p>
        </div>
      </div>
      <div className={ contactStyles.grid }>
        <section className={ contactStyles.contactInfo }>
          <div className={ contactStyles.ourOffice }>
            <div>
              <IconContext.Provider value={{ className: contactStyles.icon }}>
                <FaMapMarkerAlt />
              </IconContext.Provider>
              <h5>Our Office</h5>
            </div>
            <p><span>ED IMC FIRM</span></p>
            <p>The IMC Block</p>
            <p>141 Scout Rallos Extension, Sacred Heart</p>
            <p>Quezon City, Metro Manila PH 1103</p>
          </div>
          <div className={ contactStyles.trunkLines }>
            <div>
              <IconContext.Provider value={{ className: contactStyles.icon }}>
                <FaPhoneAlt />
              </IconContext.Provider>
              <h5>Trunk Lines</h5>
            </div>
            <p>(02) 8925-4761</p>
            <p>(02) 8426-4844</p>
            <p>(02) 3413-6436</p>
            <p>(02) 3448-5386</p>
          </div>
          <div className={ contactStyles.emails }>
            <div>
              <IconContext.Provider value={{ className: contactStyles.icon }}>
                <FaEnvelopeOpenText />
              </IconContext.Provider>
              <h5>Emails</h5>
            </div>
            <p>info@execdecisions.com.ph</p>
            <p>exec.decisions@yahoo.com</p>
          </div>
        </section>
        <Map />
        <div className={ contactStyles.contactFormHeader }>
          <a onClick={ play }>Reach out to us.</a>
          <audio id="music" src={ reachOut }></audio>
          <h6>For any inquiries you might have</h6>
        </div>
        <section className={ contactStyles.contactForm }>
          <form id="message-form" onSubmit={ sendEmail }>
              <div className={ contactStyles.inlineFields }>
                <div className={ contactStyles.formGroup }>
                  <label htmlFor="firstNameField">First Name</label>
                  <input type="text" name="first_name" id="firstNameField" required={ true } />
                </div>
                <div className={ contactStyles.formGroup }>
                  <label htmlFor="lastNameField">Last Name</label>
                  <input type="text" name="last_name" id="lastNameField" required={ true } />
                </div>
              </div>
              <div className={ contactStyles.formGroup }>
                <label htmlFor="emailField">Email</label>
                <input type="email" name="email" id="emailField" required={ true } />
              </div>
              <div className={ contactStyles.formGroup }>
                <label htmlFor="subjectField">Subject</label>
                <input type="text" name="subject" id="subjectField" required={ true } />
              </div>
              <div className={ contactStyles.formGroup }>
                <label htmlFor="messageField">Message</label>
                <textarea name="message" id="messageField" rows="6" required={ true }></textarea>
              </div>
              <button type="submit">Send</button>
          </form>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage